import React, { useState } from 'react';

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
      setIsOpen(!isOpen);
  };

  return (
      <div className="mobile-menu-container">
          <div className="menu-icon" onClick={toggleMenu}>
              {isOpen ? '✖' : '☰'}
          </div>
          {isOpen && (
              <div className="menu-items">
                  <a href="/">Home</a>
                  <a href="/contact">Contact</a>
                  <a href="/about">About</a>
                  <a href="/privacy-policy">Privacy Policy</a>
                  <a href="/category/healthcare">Healthcare</a>
                  <a href="/category/finance_and_banking">Finance and Banking</a>
                  <a href="/category/education">Education</a>
                  <a href="/category/manufacturing">Manufacturing</a>
                  <a href="/category/retail_and_e_commerce">Retail and E-commerce</a>
              </div>
          )}
               <div className="main-menu-logo">
              <a className="main-menu-logo-text" href='/'><img className="main-menu-logo-img" alt='Carambola AI' src='/carambola_logo.png'/><sapn>Carambola AI</sapn></a>        
            </div>
      </div>
  );
};

export default Menu;

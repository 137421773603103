import React from 'react';
import { Helmet } from 'react-helmet';

const Contact = () => {
  return (
    <div className="contact-container">
       <Helmet>
        <title>Carambola AI - Contact</title>
       </Helmet>
      <h1>Contact</h1>
      <p>Contact at <b><a data-auto-recognition="true" href="mailto:contact@carambo.la">contact@carambo.la</a></b></p>
    </div>
  );
};

export default Contact;

import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import PrivacyPolicy from './components/PrivacyPolicy';
import Menu from './components/Menu';
import CategoryPage from './components/CategoryPage';
import Article from './components/Article';
import NotFound from './components/NotFound';

const App = () => {
  return (
    <Router>
        <Menu />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/category/:title" element={<CategoryPage />} />
          <Route path="/article/:id" element={<Article />} />
          {/* Catch-all route for 404 - Not Found */}
          <Route path="*" element={<NotFound />} />
        </Routes>
    </Router>
  );
};

export default App;

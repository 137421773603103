import React from 'react';
import { Helmet } from 'react-helmet';

const About = () => {
  return (
    <div className="about-container">
       <Helmet>
        <title>Carambola AI - About</title>
       </Helmet>
      <h1>About Us</h1>
      <p><b>At the Forefront of AI Innovation</b></p>
      <p>Welcome to aicarambola.com, your premier destination for staying informed about the latest breakthroughs and developments in the dynamic field of artificial intelligence. We're passionate about curating and simplifying complex AI advancements, making them accessible and understandable to everyone, from industry professionals to curious enthusiasts.</p>
      <p><b>Powered by CarambolaAI's R&D</b></p>
      <p>This project is an initiative of the CarambolaAI Research & Development department. We're committed to leveraging our expertise to provide you with high-quality, insightful summaries and elaborations of the latest AI updates across all industries. Our team of dedicated researchers and AI specialists works tirelessly to distill complex information into clear, concise explanations, ensuring you're always in the know.</p>
      <p><b>Our Mission</b></p>
      <p>Our mission is to empower individuals and businesses to stay ahead of the curve in the rapidly evolving world of AI. We believe that knowledge is the key to unlocking the full potential of this transformative technology, and we're here to facilitate that understanding.</p>
      <p><b>What We Offer</b></p>
      <ul>
        <li><b>Comprehensive Coverage:</b> We cover the entire spectrum of AI, from machine learning and deep learning to natural language processing and robotics.</li>
        <li><b>Industry-Specific Insights:</b> We delve into how AI is revolutionizing various sectors, including healthcare, finance, manufacturing, and more.</li>
        <li><b>Timely Updates:</b> We stay on top of the latest research papers, conferences, and product releases to keep you informed about the cutting-edge.</li>
        <li><b>Clear Explanations:</b> We break down complex concepts into easy-to-grasp summaries and elaborations, ensuring you can follow along even if you're new to AI.</li>
      </ul>
      <p><b>Join the AI Revolution</b></p>
      <p>We invite you to explore our website and join us on this exciting journey into the future of artificial intelligence. Whether you're a seasoned AI professional or simply curious about this groundbreaking technology, we're here to provide you with the knowledge and insights you need to stay informed and inspired.</p>
      <p><b>CarambolaAI: Pioneering the Future of AI</b></p>
    </div>
  );
};

export default About;
